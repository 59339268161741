// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
global.$ = $;
global.jQuery = $;
import select2 from "select2"
import 'select2/dist/css/select2.css'
window.addEventListener('DOMContentLoaded', () => {
    $('#select_city').select2();
    $('#select_sabeq_city').select2();
    $('#select_shiply_city').select2();
})

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
    if (window.scrollY > 130) {
      if (document.getElementById('desktop_header')) {
        document.getElementById('desktop_header').classList.add('fixed-top');
      }
    } else {
      if (document.getElementById('desktop_header')) {
        document.getElementById('desktop_header').classList.remove('fixed-top');
      }
    }
  });
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()
